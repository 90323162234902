import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection1 from "./Sections/ProductSection1.js";
import PaginationSection from "./Sections/PaginationSection.js";
import TitleSection from "./Sections/TitleSection.js";
import TablePricesSection from "./Sections/TablePricesSection.js";
import ProductSection2 from "./Sections/ProductSection2.js";
import ProductSection3 from "./Sections/ProductSection3.js";
import ProductSection4 from "./Sections/ProductSection4.js";
import ProductSection5 from "./Sections/ProductSection5.js";
import ProductSection6 from "./Sections/ProductSection6.js";
import ProductSection7 from "./Sections/ProductSection7.js";
import ProductSection8 from "./Sections/ProductSection8.js";
import ProductSection9 from "./Sections/ProductSection9.js";
import TimeTableSection from "./Sections/TimeTableSection.js";

import { pages_titles_n_indexes_dict } from 'components/Pagination/PaginationDict';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

const sections = {
  1: ProductSection1,
  2: ProductSection2,
  3: ProductSection3,
  4: ProductSection4,
  5: ProductSection5,
  6: ProductSection6,
  7: ProductSection7,
  8: ProductSection8,
  9: ProductSection9,
}

export default function OfferPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const id_page = pages_titles_n_indexes_dict[props.match.params.query];

  if (!id_page) {
    return 'Wassap noti booi?'
  }
  const SectionRender = sections[id_page];

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand='Масква'
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white"
        }}
        {...rest}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <TitleSection titleid={id_page} />
          <SectionRender />
          <TablePricesSection tagid={id_page} />
          <TimeTableSection tagid={id_page} />
          <PaginationSection desire_page={id_page}/>
        </div>
      </div>
      <Footer />
    </div>
  );
}
