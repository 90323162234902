import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/5/1.jpg";
import img2 from "assets/img/offers/5/2.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection5() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Ламинирование ресниц</h3>
          <Muted>
            Возможность добиться естественным путем более густых ресниц, с красивым изгибом и насыщенным цветом;
            Защита волосков от воздействий окружающей среды;
            <br/><br/>
            Этапы процедуры:
            <ol>
                <li>Косметолог предварительно обрабатывает волоски обезжиривающим составом;</li>
                <li>Нанесение размягчающего средства по всей длине ресниц;</li>
                <li>Закрепление гибкого валика, предотвращающего склеивание волосков и создающего изгиб;</li>
                <li>Нанесение сыворотки для создания объема;</li>
                <li>Обработка ресниц кератиновым составом.</li>
              </ol>
            Ламинирование ресниц держится около одного месяца и не требует никакого дополнительного ухода.

          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Ламинирование бровей</h3>
          <Muted>
          Волоски утолщаются и становятся мягче.
          <br/><br/>
          Цвет после окрашивания сохраняется дольше.
          <br/><br/>
          Ламинирование может скорректировать неудачный татуаж. Процедура поможет скрыть неправильные линии и придать бровям естественный вид.
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

      </GridContainer>
    </div>
  );
}
