import React from "react";
import {Helmet} from "react-helmet";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

const titles = {
  1: 'Кабинет косметики',
  2: 'Массажный кабинет',
  3: 'Чистка лица',
  4: 'Депиляция',
  5: 'Оформление бровей и ресниц',
  6: 'Кабинет маникюра и педикюра',
  7: 'Парикмахерский зал',
  8: 'Макияж',
  9: 'Кедровая бочка и солярий',
}

export default function TitleSection(props) {
  const classes = useStyles();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div className={classes.section}>
    <Helmet>
        <title>Профессиональная услуга: {titles[props.titleid]} за доступную цену.</title>
        <meta name="description" content={"Все подробности об оказываемой услуге: " + titles[props.titleid] + ". Доступные процедуры и информация о каждом этапе."} />
    </Helmet>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>{titles[props.titleid]}</h2>
          {/* <h5 className={classes.description}>
            This is the paragraph where you can write more details about your
            product. Keep you user engaged by providing meaningful information.
            Remember that by this time, the user is curious, otherwise he wouldn
            {"'"}t scroll to get here. Add a button if you want the user to see
            more.
          </h5> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
