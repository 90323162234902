import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import LoupeIcon from '@material-ui/icons/Loupe';
import GroupIcon from '@material-ui/icons/Group';
import StarRateIcon from '@material-ui/icons/StarRate';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";

import image1 from "assets/img/sale1.jpg";
import image2 from "assets/img/sale_vip.jpg";
import image3 from "assets/img/sale3.png";

import styles from "assets/jss/material-kit-react/views/componentsSections/pillsStyle.js";

const useStyles = makeStyles(styles);

export default function SectionSales() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={8} md={12} lg={12}>
              <NavPills
                color="danger"
                horizontal={{
                  tabsGrid: { xs: 12, sm: 4, md: 4 },
                  contentGrid: { xs: 12, sm: 4, md: 4 }
                }}
                tabs={[
                  {
                    tabButton: "Vip карта",
                    tabIcon: StarRateIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image2}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Vip карта постоянного клиента.</h4>
                            <p>
                              С этой картой каждая 5 процедура абсолютно бесплатно.
                            </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Накопительная",
                    tabIcon: LoupeIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image1}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Дисконтные накопительные карты постоянного клиента.</h4>
                            <p>
                              Гибкая система накопления несгорающих бонусов.
                            </p>
                      </span>
                    )
                  },
                  {
                    tabButton: "Акции",
                    tabIcon: GroupIcon,
                    tabContent: (
                      <span>
                          <img
                            style={{height: "300px", width: "auto", display: "block", margin: "-10px"}}
                            className={classes.imgCardTop}
                            src={image3}
                            alt="Card-img-cap"
                          />
                            <h4 className={classes.cardTitle}>Акции в социальных сетях.</h4>
                            <p>
                              Всегда уведомляем обо всех акциях наших подписчиков в Instagram.
                            </p>
                      </span>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
