import React from "react";
import { YMaps, Map, Placemark, GeolocationControl } from "react-yandex-maps";
import { dangerColor } from "assets/jss/material-kit-react.js";

const mapData = {
  center: [52.277941, 104.327049],
  zoom: 16,
};

export default function YandexMap() {

  return (
  <YMaps>
    <Map
      defaultState={mapData}
      width="100%"
      options={{suppressMapOpenBlock: true}}
      instanceRef={ref => {
        if (ref) {
          ref.events.add("click", e => {
            ref.balloon.close();
          });
        }
      }}
    >
      <GeolocationControl />
      <Placemark
        modules={["geoObject.addon.balloon"]}
        geometry={mapData.center}
        options={{preset: "islands#icon", iconColor: dangerColor, }}
        properties={{
          balloonContent: "<strong>Салон Масква</strong>. Иркутск ул. Омулевского, 5"
        }}
      />
    </Map>
  </YMaps>
);
}
