import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/4/1.jpg";
import img2 from "assets/img/offers/4/2.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection4() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Депиляция воском</h3>
          <Muted>
          Результат держится до месяца. В зависимости от типа волос и интенсивности их роста, гладкая кожа будет вас радовать от двух недель до месяца;
          <br/>
          Восковая эпиляция снижает количество волос и осветляет их, делая очень тонкими;
          <ul>
              <li>Возможность полностью отказаться от бритвы;</li> <li>Возможность избавиться от старой ороговевшей кожи.</li>
            </ul>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Шугаринг</h3>
          <Muted>
          Удаление всех видов волос. Сахарной пасте поддается пушок, грубые и жесткие волосы, независимо от цвета и густоты.
          <br/><br/>
          Отсутствие раздражения. Кожа становится идеально гладкая. После первых процедур возможно покраснение, но оно быстро проходит. Нет вросших волос. Вытягивание стержня проводится по росту, что предотвращает подкожный загиб, воспаление, не провоцирует нагноение.
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

      </GridContainer>
    </div>
  );
}
