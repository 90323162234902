import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/2/1.jpg";
import img2 from "assets/img/offers/2/2.jpg";
import img3 from "assets/img/offers/2/3.jpg";
import img4 from "assets/img/offers/2/4.jpg";
import img5 from "assets/img/offers/2/5.jpg";
import img6 from "assets/img/offers/2/6.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection2() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Лимфодренажный массаж лица</h3>
          <Muted>
          Процедура, направленная на стимуляцию движения лимфы путем проведения ряда массажных движений.
          <br/><br/>
          Улучшает циркуляцию крови и лимфы по сосудам, стимулирует выведение токсинов из организма, нормализует работу всех структурных элементов эпидермиса и дермы, повышает тургор и эластичность кожных волокон, что позволяет добиться получения выраженного эффекта лифтинга и способствует улучшению овала лица.
          <br/>
          Курс процедур массажа помогает избавиться от мимических морщин, отеков и «мешков» под глазами и улучшает цвет лица.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Буккальный массаж</h3>
          <Muted>
            Массаж подтягивает и укрепляет мышцы, что помогает сделать овал лица четким и выразительным. Процедура также способствует уменьшению объема щек.
            <br/><br/>
            После массажа исчезает носогубная складка, становятся менее заметными или полностью растворяются морщины вокруг губ.
            Кожа лица обретает красивый цвет, становится гладкой, эластичной и сияющей.
            <br/><br/>
            Техника выполнения буккального массажа включает две основных фазы:
              <ol>
                <li>Скульптурная предполагает внешнюю, но эффективную проработку по основным массажным линиям области лица;</li>
                <li>Глубокотканная фаза – это воздействие изнутри, то есть через рот, при которой прорабатываются не только кожные покровы и подкожная клетчатка, но и внутренние мягкие ткани, мышцы.</li>
              </ol>
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img3}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Классический массаж</h3>
          <Muted>
            Уменьшает напряжение мышц, растяжение связок, устраняет судороги, повышает тонус мышц и всего организма, даёт больше энергии и одновременно даёт расслабляющий эффект.
            <br/><br/>
            Восстанавливает контуры тела и уменьшает подкожный жир, мышцы становятся эластичными, уменьшает отёки, помогает укрепить спину, усиливает циркуляцию крови.
            <br/><br/>
            Он улучшает циркуляцию крови и лимфы, расслабляет уставшие мускулы и улучшает подвижность суставов, обеспечивает общую релаксацию улучшает функционирование иммунной системы, тем самым помогает организму прийти к оптимальному равновесию.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Антицеллюлитный массаж</h3>
          <Muted>Улучшится состояние вашей кожи – она становится упругой, подтянутой и гладкой.
<br/><br/>
При курсе данной процедуры наблюдается не только исчезновение неприятных отложений в проблемных местах, но и ускоряется обмен веществ, с помощью чего вы избавитесь от лишних сантиметров в бедрах.
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img4}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img5}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Медовый массаж</h3>
          <Muted>
            Повышает тонус мышц, а также улучшает крово- и лимфообращение, усиливает иммунитет.
            <br/><br/>
            Техника массажа с использованием меда включает в себя элемент пилинга, в результате чего кожа интенсивно очищается и одновременно снабжается биологически активными веществами.
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Вакуумный массаж</h3>
          <Muted>
            Является эффективной косметической и терапевтической процедурой. Осуществляется он с помощью специального аппарата или вакуумных банок. Такая процедура имеет многосторонний эффект:
            <ul>
            <li>активизируется процесс обмена веществ и кровообращение,</li>
            <li>ткани насыщаются кислородом,</li>
            <li>расслабляются спазмированные мышцы,</li>
            <li>разрушаются жировые клетки.</li>
            </ul>
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img6}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
