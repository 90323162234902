import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";

import img1 from "assets/img/offers/6/1.jpg";
import img2 from "assets/img/offers/6/2.jpg";
import img3 from "assets/img/offers/6/3.jpg";
import img4 from "assets/img/offers/6/4.jpg";
import img5 from "assets/img/offers/6/5.jpg";
import img6 from "assets/img/offers/6/6.jpg";
import img7 from "assets/img/offers/6/7.jpg";
import img8 from "assets/img/offers/6/8.jpg";
import img9 from "assets/img/offers/6/9.jpg";
import img10 from "assets/img/offers/6/10.jpg";
import img11 from "assets/img/offers/6/11.jpg";
import img12 from "assets/img/offers/6/12.jpg";
import img13 from "assets/img/offers/6/13.jpg";
import img14 from "assets/img/offers/6/14.jpg";
import img15 from "assets/img/offers/6/15.jpg";
import img16 from "assets/img/offers/6/16.jpg";
import img17 from "assets/img/offers/6/17.jpg";
import img18 from "assets/img/offers/6/18.jpg";

import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={img1} alt="First slide" className="slick-image" />
                </div>
                <div>
                  <img src={img4} alt="First slide" className="slick-image" />
                </div>
                <div>
                  <img
                    src={img5} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img6} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img src={img7} alt="First slide" className="slick-image" />
                </div>
                <div>
                  <img
                    src={img8} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img9} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img src={img10} alt="First slide" className="slick-image" />
                </div>
                <div>
                  <img
                    src={img11} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img12} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img
                    src={img13} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img14} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img
                    src={img15} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img16} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img src={img17} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img src={img18} alt="Third slide" className="slick-image" />
                </div>
                <div>
                  <img src={img2} alt="Second slide" className="slick-image" />
                </div>
                <div>
                  <img src={img3} alt="Third slide" className="slick-image" />
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
