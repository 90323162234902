const pages_titles_n_indexes_dict = {
  1: 'beautician',
  2: 'massage',
  3: 'face-cleaning',
  4: 'depilation',
  5: 'eyebrow-&-eyelash',
  6: 'nail-bar',
  7: 'hairdresser',
  8: 'makeup',
  9: 'cedar-barrel-&-solarium',
  'beautician': 1,
  'massage': 2,
  'face-cleaning': 3,
  'depilation': 4,
  'eyebrow-&-eyelash': 5,
  'nail-bar': 6,
  'hairdresser': 7,
  'makeup': 8,
  'cedar-barrel-&-solarium': 9,
}

const max_offer_page_index = 9

export { pages_titles_n_indexes_dict, max_offer_page_index };
