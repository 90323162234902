import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Muted from "components/Typography/Muted.js";

import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  childPriceTable: {
    marginLeft: '-15px',
    // padding: '0 30px',
  },
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell style={{ width: '10px' }} >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left" >
          {row.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>

              {row.children_list.map((row) => (
                <div key={row.pk} >
                  <Typography variant="h6" gutterBottom component="div">
                    {row.name}
                  </Typography>

                  <Table size="small" aria-label="purchases" className={classes.childPriceTable}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Услуга</TableCell>
                        <TableCell align="right">
                          Цена, руб.
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.children_list.map((row) => (
                        <TableRow key={row.pk}>
                          <TableCell component="th" scope="row">
                            {row.name}
                            <Muted>{row.add_text}</Muted>
                          </TableCell>
                          <TableCell align="right">
                            {row.sale ? (
                              <Tooltip
                                disableFocusListener
                                disableTouchListener
                                title={ row.sale }
                                placement={window.innerWidth > 959 ? "right" : "top"}
                                classes={{ tooltip: classes.tooltip }}
                              >
                              <Button color="rose" size="sm">
                                {row.price}
                              </Button>
                            </Tooltip>
                          ) : (
                            <Button color="transparent" size="sm">
                              {row.price}
                            </Button>
                          )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              ))}

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(props) {


  return (
      <Table aria-label="collapsible table">
        <TableBody>
          {props.data.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
  );
}
