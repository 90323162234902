import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import CollapsibleTable from "components/Table/PriceTable.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

import { useFetch } from "hookFetchData";

const DJANGO_API_URL = process.env.REACT_APP_DJANGO_API_URL || "http://localhost:8000/"

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();

  const [data, loading, nameError] = useFetch(
    DJANGO_API_URL + "api/mascow/fetch/prices"
  );

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Наш Price-лист</h2>
          <h5 className={classes.description}>
            Ниже представлены актуальные цены салона Масква. Доступные скидки для наглядности выделены красноватым цветом (;
          </h5>

          {(() => {
            if (loading) {
              return (
                <div className={classes.section} id="notifications">
                  <SnackbarContent
                    message={
                      <span>
                        <b>Загрузка:</b> Получаем данные от сервера...
                      </span>
                    }
                    close
                    color="info"
                    icon="info_outline"
                  />
              </div>
              )
            } else if (nameError) {
              return (
                <SnackbarContent
                  message={
                    <span>
                      <b>Oops =(</b> Что-то пошло не так, тарифы не загрузились, попробуйте позднее...
                    </span>
                  }
                  close
                  color="warning"
                  icon="info_outline"
                />
              )
            } else {
              return (
                data.length != 0 ? (
                  <CollapsibleTable data={data} />
                ) : (
                  <SnackbarContent
                    message={
                      <span>
                        <b>Oops:</b> Прайс-лист еще не готов =
                      </span>
                    }
                    close
                    color="info"
                    icon="info_outline"
                  />
                )
              )
            }
          })()}

        </GridItem>
      </GridContainer>
    </div>
  );
}
