import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

function createData(day, worktime) {
  return { day, worktime };
}

const rows = [
  createData('Понедельник', '9:00 - 21:00'),
  createData('Вторник', '9:00 - 21:00'),
  createData('Среда', '9:00 - 21:00'),
  createData('Четверг', '9:00 - 21:00'),
  createData('Пятница', '9:00 - 21:00'),
  createData('Суббота', '9:00 - 21:00'),
  createData('Воскресенье', '9:00 - 21:00'),
];

export default function TimeTable() {
  const classes = useStyles();

  return (
      <Table className={classes.table} aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.day}>
              <TableCell component="th" scope="row">
                {row.day}
              </TableCell>
              <TableCell align="right">{row.worktime}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
  );
}
