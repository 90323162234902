import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import RateReviewIcon from '@material-ui/icons/RateReview';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/completedStyle.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function SectionJavascript() {
  const classes = useStyles();
  const [classicModal, setClassicModal] = React.useState(false);
  return (
    <div id="about" className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">

          <GridItem xs={12} sm={12} md={8}>
            <h2>О Салоне</h2>
            <h4>
              В нашем салоне возможно сделать все услуги в одном месте,
              мы работаем на качественных материалах, для постоянных клиентов
              действует выгодная система скидок. Радуем наших клиентов более 6 лет.
            </h4>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} lg={4}>
                <Button
                  color="danger"
                  block
                  onClick={() => setClassicModal(true)}
                >
                  <RateReviewIcon className={classes.icon} />
                  Отзывы клиентов
                </Button>
                <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                    <IconButton
                      className={classes.modalCloseButton}
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={() => setClassicModal(false)}
                    >
                      <Close className={classes.modalClose} />
                    </IconButton>
                    <h4 className={classes.modalTitle}>Отзывы клиентов | Лирика</h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      На нашем сайте мы не публикуем и не оставляем отзывы клиентов, полагая, что это необъективно. Но мы доверяем этот процесс таким IT-гигантам как Яндекс в России и Гугл. У этих сервисов достаточно ресурсов и мощностей, чтобы автоматизированно фильтровать отзывы и оставлять отклики только действительных клиентов, тем самым формируя практически значимую статистику.
                    </p>
                    <p>
                      Наши менеджеры регулярно просматривают и отвечают на отзывы. Ваши комментарии, оценки и предложения помогают нам становиться лучше, руководству и администраторам салона видеть и исправлять недочеты. Все же Ваши просто положительные отзывы всегда мотивируют весь наш коллектив, и, ах) это то, что всегда только радует и придает сил в работе!
                    </p>
                    <p style={{align:"right"}}>
                      Искренне Ваша, Масква
                    </p>
                    <Button
                      color="rose"
                      href="https://yandex.ru/maps/-/CCQd66czgB"
                      target="_blank"
                      >
                      Яндекс.Отзывы
                    </Button>
                    <Button
                      color="info"
                      href="https://g.page/Salon-Maskva/review?rc"
                      target="_blank"
                      >
                      Google.Отзывы
                    </Button>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Закрыть
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
            </GridContainer>
          </GridItem>


        </GridContainer>
      </div>
    </div>
  );
}
