import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/3/1.jpg";
import img2 from "assets/img/offers/3/2.jpg";
import img3 from "assets/img/offers/3/3.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection3() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Механическая чистка</h3>
          <Muted>
Показания для механической чистки – жирная кожа с забитыми порами, невоспаленная угревая сыпь.
При этом способе комедоны удаляют вручную.
            <br/><br/>
            Этапы процедуры:<ol>
              <li>Демакияж;</li> <li>распаривание кожи;</li> <li>Очищение лица механическим путём;</li>
            <li>Нанесение мазей для быстрого заживление кожи.</li></ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Ультразвуковая чистка</h3>
          <Muted>
Во время этой процедуры на лицо воздействуют ультракороткие волны, которые глубоко проникают в кожу. Такая чистка не травмирует кожу.
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img3}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Парафиновая маска лица</h3>
          <Muted>
          Комбинированная чистка лица
Комбинированная чистка лица сочетает в себе механическую и ультразвуковую чистки лица.
            <br/><br/>
            Этапы процедуры:
            <ol>
            <li>Демакияж;</li>
            <li>Распаривание кожи;</li>
            <li>Очищение лица механическим путём;</li>
            <li>Очищение лица с помощью ультразвукового аппарата;</li>
            <li>Нанесение мазей для быстрого заживление кожи.</li>
            </ol>
          </Muted>
        </GridItem>

      </GridContainer>
    </div>
  );
}
