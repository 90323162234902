import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Muted from "components/Typography/Muted.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

import img1 from "assets/img/offers/1/1.jpg";
import img2 from "assets/img/offers/1/2.jpg";
import img3 from "assets/img/offers/1/3.jpg";
import img4 from "assets/img/offers/1/4.jpg";
import img5 from "assets/img/offers/1/5.jpg";

const useStyles = makeStyles(styles);

export default function ProductSection1() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img1}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Пилинги</h3>
          <Muted>
            Задача любого пилинга - отшелушивание верхних слоёв эпидермиса.
            <br/><br/>
            Показания к пилингу: акне, постакне, расширенные поры, гиперпигментация, снижение эластичности и тургора кожи, наличие мелких или глубоких морщин.
            <br/><br/>
            Этапы процедуры:<ol>
              <li>Демакияж;</li> <li>Нанесение кислоты;</li> <li>Нейтрализация;</li>
            <li>Удаление остатков кислоты.</li></ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Альгинатные маски</h3>
          <Muted><br/><br/>
          Этапы процедуры:<ol><li>Первым этапом процедуры является очищение кожи от косметики, применения увлажняющих сывороток;</li>
          <li>После этого косметолог разводит порошок альгината водой и наносит маску на кожу лица толстым слоем;</li>
          <li>Затем, по истечении двадцати минут, застывшая маска аккуратно снимается с лица;</li>
          <li>В конце косметолог наносит на кожу клиента различные сыворотки и крем, эффективность которых повышается во много раз после применения альганата;</li>
          </ol>
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img2}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img3}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Парафиновая маска лица</h3>
          <Muted>
            Эта маска направлена на глубокое увлажнение кожи, она прекрасно восстанавливают водный баланс;
            После снятия маски уходит отечность, кожа становится упругая, мягкая и свежая.
            <br/><br/>
            Этапы процедуры:
            <ol><li>Этап очищение кожи;</li>
            <li>Этап нанесение питательного крема и парафиновой маски;</li>
            <li>удаление парафиновой маски и нанесение завершающего ухода.</li>
            </ol>
          </Muted>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Гипсовая маска</h3>
          <Muted>Создает эффект сауны, что позволяет другим компонентам проникнуть в глубь кожи; улучшает обменные процессы; усиливает циркуляцию крови; устранение отеков.
            <br/><br/>
            Этапы процедуры:
          <ul>
            <li>Демакияж;</li>
            <li>Нанесение крема/сыворотки/масла и гипсовой маски;</li>
            <li>Завершающий уход;</li>
          </ul>
          </Muted>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img4}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}><br/><br /></GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <img
            src={img5}
            alt="..."
            className={classes.imgRounded + " " + classes.imgFluid}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <h3 className={classes.title}>Микротоки</h3>
          <Muted>
            В основе микротоковой терапии лежит слабый импульсный ток низкой частоты. Аппарат оказывает очень мягкое воздействие на клетки, которые, в итоге, ведет к улучшению многих показателей. Микротоки очень аккуратно, но глубоко проникают в кожу, ускоряя микроциркуляцию, обеспечивая легкий лимфодренажный эффект.
            <br/><br/>
            Устраняются мешки под глазами, проходит отечность век, улучшается овал лица, происходит снабжение клеток кислородом, который делает кожу более свежей и здоровой.
            <br/><br/>
            Процедура оказывает благотворное влияние на мышечную ткань, кожные покровы и лимфатические узлы.
          </Muted>
        </GridItem>
      </GridContainer>
    </div>
  );
}
