import React from "react";
import { HashLink as Link } from 'react-router-hash-link';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// images
import Image1 from "assets/img/offers/1/logo.jpg";
import Image2 from "assets/img/offers/2/logo.jpg";
import Image3 from "assets/img/offers/3/logo.jpg";
import Image4 from "assets/img/offers/4/logo.jpg";
import Image5 from "assets/img/offers/5/logo.jpg";
import Image6 from "assets/img/offers/6/logo.jpg";
import Image7 from "assets/img/offers/7/logo.jpg";
import Image8 from "assets/img/offers/8/logo.jpg";
import Image9 from "assets/img/offers/9/logo.jpg";
// for cards
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

import { useFetch } from "hookFetchData";
import { max_offer_page_index, pages_titles_n_indexes_dict } from 'components/Pagination/PaginationDict';

const DJANGO_API_URL = process.env.REACT_APP_DJANGO_API_URL || "http://localhost:8000/"
const API_NOTIFICATIONS_FOR_OFFERS = process.env.REACT_APP_API_NOTIFICATIONS_FOR_OFFERS || "api/mascow/fetch/notificationoffers"

const offerContent = [
  {title: 'Кабинет косметики', content: 'Пилинги, маски по типу кожи, аппаратная косметология.', image: Image1},
  {title: 'Массажный кабинет', content: 'Классический, антицеллюлитный, массаж лица.', image: Image2},
  {title: 'Чистка лица', content: 'Механическая, ультразвуковая, комбинированная, атравматическая.', image: Image3},
  {title: 'Депиляция', content: 'Воск, шугаринг.', image: Image4},
  {title: 'Оформление бровей и ресниц', content: 'Окрашивание, оформление, ламинирование.', image: Image5},
  {title: 'Кабинет маникюра и педикюра', content: 'Профессиональный маникюр и педикюр.', image: Image6},
  {title: 'Парикмахерский зал', content: 'Стрижки, окрашивания.', image: Image7},
  {title: 'Макияж', content: 'Где сделать макияж без лишних слов.', image: Image8},
  {title: 'Кедровая бочка и солярий', content: 'Удобная тарификация. Система скидок.', image: Image9},
]

const useStyles = makeStyles(styles);

export default function SectionNavbars() {
  const classes = useStyles();

  const [data, loading, nameError] = useFetch(
    DJANGO_API_URL + API_NOTIFICATIONS_FOR_OFFERS
  );

  const height = "180px";

  const getOffersContent = () => {
    let content = [];
    for (let i = 0; i < max_offer_page_index; i++) {
      content.push(
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            {((loading) || (nameError) || (data.length != max_offer_page_index)) ? (
              <div></div>
            ) : (
              <CardHeader color={data[i].type}>{data[i].text4user}</CardHeader>
            )}
            <img
              style={{height: height, width: "100%", display: "block"}}
              className={classes.imgCardTop}
              src={offerContent[i].image}
              alt="Card-img-cap"
            />
            <CardBody>
              <h4 className={classes.cardTitle}>{offerContent[i].title}</h4>
              <p>
                {offerContent[i].content}
              </p>
              <Link to={"/offer/" + pages_titles_n_indexes_dict[i+1]} className={classes.link}>
                <Button color="danger">Подробнее</Button>
              </Link>
            </CardBody>
          </Card>
        </GridItem>
      );
    }
    return content;
  };

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          {getOffersContent()}
        </GridContainer>
      </div>
    </div>
  );
}
