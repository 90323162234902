// hooks.js
import { useState, useEffect } from "react";

function useFetch(url) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nameError, setNameError] = useState(false);

  async function fetchUrl() {
    const response = await fetch(url);
    if (response.ok) {
      response.json().then(res => setData(res)).catch(err => setNameError(err));
      setLoading(false);
    } else {
      setNameError(response.status);
      setLoading(false);
    }
  }

  const userUrl = window.location.pathname.split('/').pop();
  useEffect(() => {
    fetchUrl();
    }, [userUrl]);

  return [data, loading, nameError];
}
export { useFetch };
